import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { FullScreenLayoutComponent } from './layouts/full-screen-layout/full-screen-layout.component';
import { AuthenticationService } from './core/services/authentication.service';
import { NoSpacesDirective } from './shared/directives/no-space.directive';
import { CommonService } from './shared/services/common.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import en from '@angular/common/locales/en';
import { en_US } from 'ng-zorro-antd/i18n';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { tokenInterceptor } from './core/interceptors/token.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authFeature } from './modules/auth/state/auth.state';
import { createLocalStorageSyncReducer } from './state/meta-reducer';
import { TabManagerService } from './core/services/tab-manager.service';
import { locationFeature } from './modules/location-tree/state/location-tree.state';
import { assetsFeature } from './modules/assets/state/assets.state';
import { reportFeature } from './modules/reports/state/report.state';
import { appFeature } from './state/app.state';
import { GetSupportComponent } from './modules/profile/get-support/get-support.component';
import { UserProfileComponent } from './modules/profile/user-profile/user-profile.component';
import { NzConfig, provideNzConfig } from 'ng-zorro-antd/core/config';
import { DeepLinkingService } from './core/services/deep-linking.service';
import { TrainingVideosComponent } from './shared/components/training-videos/training-videos.component';
import { TicketServiceService } from './modules/profile/ticketService.service';

registerLocaleData(en);
const ngZorroConfig: NzConfig = {
  message: {
    nzTop: 50,
    nzMaxStack: 1,
  },
};

interface RootState {
  [appFeature.name]: ReturnType<typeof appFeature.reducer>;
  [authFeature.name]: ReturnType<typeof authFeature.reducer>;
  [locationFeature.name]: ReturnType<typeof locationFeature.reducer>;
  [assetsFeature.name]: ReturnType<typeof assetsFeature.reducer>;
  [reportFeature.name]: ReturnType<typeof reportFeature.reducer>;
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    FullScreenLayoutComponent,
    NoSpacesDirective,
    GetSupportComponent,
    UserProfileComponent,
    TrainingVideosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    NgApexchartsModule,
    StoreModule.forRoot(
      {
        [appFeature.name]: appFeature.reducer,
        [authFeature.name]: authFeature.reducer,
        [locationFeature.name]: locationFeature.reducer,
        [assetsFeature.name]: assetsFeature.reducer,
        [reportFeature.name]: reportFeature.reducer,
      },
      {
        metaReducers: [
          createLocalStorageSyncReducer<RootState>('app', appFeature.name),
          createLocalStorageSyncReducer<RootState>('auth', authFeature.name),
          createLocalStorageSyncReducer<RootState>('location', locationFeature.name),
          createLocalStorageSyncReducer<RootState>('assets', assetsFeature.name),
          createLocalStorageSyncReducer<RootState>('report', reportFeature.name),
        ],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    provideNzConfig(ngZorroConfig),
    provideAnimationsAsync('noop'),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    AuthenticationService,
    CommonService,
    TabManagerService,
    DeepLinkingService,
    TicketServiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
